<template>
  <div class="mister">
      <div class="uk-card uk-card-default uk-card-body ">
          <button-group>
              <form-button
                      :icon="isLanguageRtl() ? 'chevron-right': 'chevron-left'"
                      @click="$router.back()">{{safeTranslate('sgirot.project.backToProjects')}}</form-button>
          </button-group>
          <h1 class="uk-card-title">
              <slot name="pageTitle">{{ translate('sgirot.project.showTitle') }}</slot>
          </h1>
          <div class="page-content">

              <skeleton :template="'lines'"
                        :count="10"
                        :fitHeight="false"
                        :defaultHeight="500"
                        :contentReady=" ! asyncOps.asyncStatus.asyncDataLoading">
                  <table class="uk-table uk-table-striped" v-if="! asyncOps.asyncStatus.asyncDataLoading">
                      <tbody>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.createdAt')}}</td>
                          <td>{{ projectCreatedAt }}</td>
                      </tr>
                      <tr v-if="$store.getters['user/profile'].isSuperAdmin">
                          <td class="uk-table-shrink" >{{translate('sgirot.project.show.expertName')}}</td>
                          <td>{{ project.expertName }}</td>
                      </tr>
                      <tr >
                          <td class="uk-table-shrink" >{{translate('sgirot.project.show.clientName')}}</td>
                          <td>{{ project.clientName }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.address')}}</td>
                          <td>{{ project.address }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.city')}}</td>
                          <td>{{ project.city }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.clientPhoneNumber')}}</td>
                          <td>{{ project.clientPhoneNumber }}</td>
                      </tr>
                      <tr v-if="$store.getters['user/profile'].isSuperAdmin">
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.sourceName')}}</td>
                          <td>{{ project.sourceName }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.description')}}</td>
                          <td>{{ project.description }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.dueDate')}}</td>
                          <td>{{ projectDueDate }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.price')}}</td>
                          <td>{{ project.price }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.percentCommission')}}</td>
                          <td>{{ project.percentCommission }}</td>
                      </tr>
                      <tr>
                          <td class="uk-table-shrink">{{translate('sgirot.project.show.status')}}</td>
                          <td>{{ projectDisplayStatus }}</td>
                      </tr>
                      </tbody>
                  </table>
              </skeleton>

          </div>
      </div>

  </div>
</template>

<script>

import {computed} from "vue";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';

import {useStore} from "vuex";


export default {

    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}  = useMeta({
                                  title: useI18n().t('sgirot.project.showTitle')
                              }).meta
        return {asyncOps, asyncOpsReady, asyncStatus, meta}
    },

    props: {
        itemId : {
            default: null
        }
    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data () {
        return {
            project : false,
            statuses: {},
            asyncData : {
                statuses: 'project/status',
                project: {
                    target: computed( () => {return 'project/'+this.itemId})
                }
            },

        }
    },
    computed: {
        projectCreatedAt () {
            if ( ! this.project ) {
                return '';
            }
            let date = new Date(this.project.createdAt);
            return date.toLocaleDateString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'});
        },
        projectDueDate () {
            if ( ! this.project ) {
                return '';
            }
            let date = new Date(this.project.dueDate);
            return date.toLocaleDateString('en-GB', {year: 'numeric', month: 'numeric', day: 'numeric'});
        },
        projectDisplayStatus() {
            if ( ! this.project ) {
                return '';
            }
            if (this.statuses && typeof this.statuses === 'object' && this.statuses.hasOwnProperty(this.project.status)) {
                return this.safeTranslate(this.statuses[this.project.status].label);
            }
            return this.safeTranslate('sgirot.statuses.0');

        }
    },
    layout: 'dashboard',
    methods: {

    }
}
</script>
